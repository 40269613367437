import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs'; 
import { ApiService } from '../../../services/api.service'; 
import { Router, ActivatedRoute } from '@angular/router';  
import { Observable, timer } from 'rxjs'
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any = null;  
  welcomeText: string = '';
  roleMessagePrefix: string = ''; 

  userMenu = [ 
    { title: 'Update Profile', 'link':  '/pages/masters/sections/profile/update' }, 
    { title: 'Change Password', 'link':  '/pages/masters/sections/password/update' }, 
    { title: 'Log out', link: '/auth/logout' }
  ];
  
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService, 
              private authService: NbAuthService, 
              private apiService: ApiService,  
              private router: Router
  ) {
  }
  private _time$: Observable<Date> = timer(0, 1000).pipe(
    map(tick => new Date()),
    shareReplay(1)
  );
  
  get time() {
    return this._time$;
  }
  getPicture() { 
    return this.apiService.getUserImage() ;
  }
  loadUserInfo(cb) {  
    this.apiService.getUser(this.authService, (user) => {  
      user = user || { picture: this.apiService.getUserImage() , name: "Unidentified user" };   
      this.user = user; 
      this.apiService.getUserImageFromServer();
      if(cb)cb(); 
    });
    
  } 
  getRandomElementFromArray(array) {
    return array[Math.floor(Math.random() * array.length)]; 
  }
  getRandomWelcomeText() {
    return this.getRandomElementFromArray([
      'Hello',
      'Wassup',
      'Greetings',
      'Welcome',
      'Welcome back',
      'Have a great day',
      'Congrats', 
      'Congratulations',
      'Hi',
      'Hey',
      'Welcome to the gang',
      'Hola',
      'Dear',
      'Dearest',
      'Honourable',
      'Thank you for logging in' 
    ]);
  }
  getRandomRoleMessagePrefix() {
    return this.getRandomElementFromArray([
      'You are now managing the portal as a',
      'You are a',
      'You have all previlages for the role of',
      'You can manage the portal as ', 
      'Lead the band as a',
      'Big Salute for being a'
    ]);
  }
  ngOnInit() { 
    this.welcomeText =  this.getRandomWelcomeText();
    this.roleMessagePrefix =  this.getRandomRoleMessagePrefix();
    this.loadUserInfo(() => {
      
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
 
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }   
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  } 
}
