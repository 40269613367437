<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <div class = "logo-area">
      <img src = "assets/images/logo.png" />
    </div> 
    <a class="logo" href="#" (click)="navigateHome()">Rematch Backoffice Panel</a>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select> -->
  </div>
  
</div> 

<div class="header-container"> 
    
  
    <!-- <nb-select *ngIf = "allocattedServices.length&&showSelectService" [selected]="selectedServiceId" (selectedChange)="selectService($event)" status="primary">
      <nb-option *ngFor="let allocattedService of allocattedServices" [value]="allocattedService.service_id"> {{ allocattedService.name }}</nb-option>
    </nb-select>   -->
  <nb-actions size="large">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    
    <nb-action class="user-action" >
      <span class = "user-info">
        <span *ngIf = "user&&user.first_name"><small>{{welcomeText}}&nbsp;<strong>{{user.first_name}}</strong>,&nbsp;</small></span>
        <span *ngIf = "user&&user.role"><small>{{roleMessagePrefix}}&nbsp;<strong>{{user.role}}</strong></small>&nbsp;|&nbsp;</span> 
        <span><small>{{ time | async | date: 'dd MMM, YYYY' }}&nbsp;<strong>{{time | async | date: 'h:mm:ss a'}}</strong></small></span> 
      </span>
      <nb-user *ngIf="user" [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="getPicture()" >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
