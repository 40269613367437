import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import jwt_decode from 'jwt-decode';

@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor(private authService: NbAuthService) {
  }
  getDecodedAccessToken(token: string): any {
    //custom fn by Jinu 
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
  
  getRole(): Observable<string> {
    console.log("Identifying role...");
    var role = "guest";
    return this.authService.onTokenChange()
      .pipe(
        map((token: NbAuthJWTToken) => {
            console.log("Auth token validity being checked...");
            if(token.isValid()) {
                var jwtToken = token.getValue();
                console.log("Role token is "+token);
                if(jwtToken) {
                    var payload = this.getDecodedAccessToken(jwtToken);
                    console.log("JWT payload is ");
                    console.log(payload);
                    role = payload.role? payload.role: 'guest';
                    this.getDecodedAccessToken(jwtToken);

                }
            }
            console.log("Role identified as "+role);
            return role;
        }),
      );
  }
}