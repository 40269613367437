import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() editorKeyup = new EventEmitter<any>();
  @Input() value;

  editor: any;

  constructor(
    private host: ElementRef,
    private locationStrategy: LocationStrategy,
  ) { }
  initTinyMce() {
    tinymce.init({
      target: this.host.nativeElement, 
      plugins: ['link', 'paste', 'table'],
      skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`,
      setup: editor => {
        this.editor = editor;
        editor.on('keyup', () => {
          this.editorKeyup.emit(editor.getContent());
        });

        editor.on('init',  (e) => {
          if(this.value) {  
            editor.setContent(this.value);
          }
        });
      },
      height: '320',
    });

  }
  ngAfterViewInit() {
    this.initTinyMce();
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
  ngOnChanges(changes: any) {
    console.log(`Changes are `, changes);
    tinymce.remove(this.editor);
    this.initTinyMce();
}
}
