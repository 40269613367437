import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    © {{curYear}} SOCIABLE TECH VENTURES. All Rights Reserved.  | <b> <a href="https://rematch.com" target="_blank">Rematch Inc.</a></b> 
    </span>
    
  `,
})
export class FooterComponent {
  curYear = new Date().getFullYear();
}
