import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from "../../environments/api"; 
import jwt_decode from 'jwt-decode';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { 
  }
  public removeTrailingSlash(str) {
    return str.replace(/^\/|\/$/g, '');
  }
  public getHttpClient() {
    return this.httpClient;
  }
  public getFullUrl(url) {
    console.log(`get full url input`, url);
    url = `${BASE_URL}/${this.removeTrailingSlash(url)}`
    console.log(`Get url result`, url)
    return url
  }   
  public requestResetPassword(email) { 
    var url = this.getFullUrl(`accounts/send-reset-password-link`);  
    return this.httpClient.post<any>(url, { email }); 
  }
  public getProfile() { 
    var url = this.getFullUrl(`accounts/self`);  
    return this.httpClient.get<any>(url); 
  }
  public getSignUpStepInformation(userId) { 
    return this.httpClient.get<any>(this.getFullUrl(`profiles/${userId}/sign-up-step-info`));
  }
  public getUserImageFromServer() {
    return new Promise((resolve) => {
      this.getProfile().subscribe((user) => { 
        const {image, image_base} = user;  
        if(image && image_base) 
            this.setUserImage(`${image_base}${image}`);
        resolve(true); 
      });
    });
  }
  public updateProfile(data) { 
    var url = this.getFullUrl(`accounts/self`);  
    return this.httpClient.patch<any>(url, data); 
  }
  public isPasswordResetTokenValid(token) { 
    var url = this.getFullUrl(`accounts/is-reset-password-token-valid?token=${token}`);  
    return this.httpClient.get<any>(url); 
  }
  public resetPassword(token, password) {   
    return this.httpClient.post<any>(this.getFullUrl(`accounts/reset-password`), { password, token }); 
  }     
  public get404Url() {
    var url = '/pages/masters/404'; 
    return this.httpClient.get<any>(url); 
  }       
  public decodeJWT(token: string): any {
    //custom fn by Jinu 
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
  public getUser(authService: NbAuthService, cb: Function) {
    let user = null;
    let token = authService.getToken().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) { 
        var jwtToken = token.getValue();
        user = this.decodeJWT(jwtToken); 
      }  
      cb(user);
    }); 
  }
  public getLocalStorageKeyForUserImage() {
    return 'user_image';
  }
  public setUserImage(imageUrl) {
    const key = this.getLocalStorageKeyForUserImage();
    localStorage.setItem(key, imageUrl);
  }
  public getUserImage() {
    const key = this.getLocalStorageKeyForUserImage();
    return localStorage.getItem(key) || '/assets/images/default-avatar.png';
  }
  public getUserRole(authService: NbAuthService, cb: Function) {
    let role = null;
    this.getUser(authService, (user) => {
      if(user) {
        role = user.role;
      }
      cb(role);
    });
  }
  public getQuestionnairesEndPoint() { 
    let url = this.getFullUrl(`questionnaires`); 
    return url;
  }
  public getQuestionnaireUsersEndPoint(questionnaireId) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/users`); 
    return url;
  }
  public getQuestionnaireAnswersEndPoint(questionnaireId, userId) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/users/${userId}/answers`); 
    return url;
  }
  public getQuestionTypesEndPoint() {
    let url = this.getFullUrl(`questionnaires/question-types/list`); 
    return url;
  } 
  public getQuestionTypes() { 
    var url = this.getQuestionTypesEndPoint();  
    return this.httpClient.get<any>(url); 
  }
  public getAppUsersEndPoint(filter: string = 'all') {  
    const urls = {
      all: `users`,
      suspicious: `users/all/suspicious`,
      unpaid: `users/all/unpaid`,
      paid: `users/all/paid`,
      deactivated: `users?is_deactivated=1`,
      deleted: `users?is_deleted=1`
    };
    return this.getFullUrl(urls[filter]); 
  }
  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  public deleteQuestionnaire(id) {
    let url = this.getFullUrl(`questionnaires/${id}`); 
    return this.httpClient.delete<any>(url);  
  }
  public getQuestionnaire(id) {
    let url = this.getFullUrl(`questionnaires/${id}`); 
    return this.httpClient.get<any>(url);   
  }
  public getAppUser(id) {
    let url = this.getFullUrl(`users/${id}`); 
    return this.httpClient.get<any>(url);   
  }
  public createQuestionnaire(data) {
    let url = this.getFullUrl(`questionnaires/`); 
    return this.httpClient.post<any>(url, data);   
  }
  public updateQuestionnaire(id, data) {
    let url = this.getFullUrl(`questionnaires/${id}`); 
    return this.httpClient.patch<any>(url, data);   
  }
  public getQuestionsEndPoint(questionnaireId) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions`);  
    return url;
  }
  public deleteQuestion(questionnaireId, questionId) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${questionId}`); 
    return this.httpClient.delete<any>(url);  
  }
  
  public getQuestion(questionnaireId, id) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${id}`); 
    return this.httpClient.get<any>(url);   
  }
  public createQuestion(questionnaireId,data) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions`); 
    return this.httpClient.post<any>(url, data);   
  }
  public updateQuestion(questionnaireId,id, data) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${id}`);  
    return this.httpClient.patch<any>(url, data);   
  }
  public getAnswerChoicesEndPoint(questionnaireId, questionId) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${questionId}/answer-choices`);  
    return url;
  }
  public deleteAnswerChoice(questionnaireId, questionId, id) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${questionId}/answer-choices/${id}`);  
    return this.httpClient.delete<any>(url);  
  }
  public getAnswerChoice(questionnaireId, questionId, id) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${questionId}/answer-choices/${id}`);  
    return this.httpClient.get<any>(url);  
  }
  public createAnswerChoice(questionnaireId, questionId, data) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${questionId}/answer-choices`); 
    return this.httpClient.post<any>(url, data);   
  }
  public updateAnswerChoice(questionnaireId, questionId, id, data) {
    let url = this.getFullUrl(`questionnaires/${questionnaireId}/questions/${questionId}/answer-choices/${id}`); 
    return this.httpClient.patch<any>(url, data);   
  }
  public patch(url: string, data: any) { 
    return this.getHttpClient().patch<any>(url, data);   
  }
  public post(url: string, data: any) {  
    return this.getHttpClient().post<any>(url, data);   
  }
  public delete(url: string) {  
    return this.getHttpClient().delete<any>(url);   
  }
  public get(url: string) { 
    return this.getHttpClient().get<any>(url);   
  }
  public loadProfileSection(userId, section) {
    const url = this.getFullUrl(`profiles/${userId}/${section}`);
    return this.getHttpClient().get<any>(url);    
  }
  public getProfileAttribute(userId, attribute) {
    const url = this.getFullUrl(`profiles/user-attributes/${userId}/${attribute}`);
    return this.getHttpClient().get<any>(url); 
  }
  public getUserAttribute(userId, attribute) {
    return new Promise((resolve, reject) => {
      try {  
        this.getProfileAttribute(userId, attribute).subscribe((response) => { 
          const {value} = response;
          resolve(value);
        }); 
      } catch(err) {
        reject(err);
      }
    });
  }
  public getBlogTitle(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this.masters().getEndPoint('blogs', 'details', id);
        const blog = await this.getHttpClient().get<any>(url).subscribe((response) => {
          const {title} = response;
          resolve(title);
        }); 
      } catch(err) {
        reject(err);
      } 
    }); 
  }
  public getPushNotificationName(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this.masters().getEndPoint('custom-push-notifications', 'details', id);
        const notification = await this.getHttpClient().get<any>(url).subscribe((response) => {
          const {name} = response;
          resolve(name);
        }); 
      } catch(err) {
        reject(err);
      } 
    });
  }
  public getProfileSections() {
    const url = this.getFullUrl(`profiles/profile-sections`);
    return this.getHttpClient().get<any>(url); 
  }
  public reviewSuspicionReport(data) {
    const url = this.getFullUrl(`users/submit-suspicion-review`);
    return this.getHttpClient().post<any>(url, data); 
  }
  public toggleUserBlockingStatus(userId) {
    const url = this.getFullUrl(`users/${userId}/toggle-blocking-status`);
    return this.getHttpClient().post<any>(url, {}); 
  }
  public blockUser(userId) {
    const url = this.getFullUrl(`users/${userId}/block`);
    return this.getHttpClient().post<any>(url, {}); 
  }
  public deleteUser(userId) {
    const url = this.getFullUrl(`users/${userId}`);
    return this.getHttpClient().delete<any>(url); 
  }
  public markFeedBackAsRead(id) {
    const url = this.getFullUrl(`masters/feedbacks/${id}/mark-as-read`);
    return this.getHttpClient().post<any>(url, {});  
  } 
  public getNewAccessToken() {
    const url = this.getFullUrl(`accounts/new-access-token`);
    return this.getHttpClient().get<any>(url); 
  }
  public getDisplayDate(inputStr, inputFormat = 'YYYY-MM-DD HH:mm:ss', outputFormat = 'DD MMM, YYYY') {
    return inputStr? moment(inputStr, inputFormat).format(outputFormat): null; 
  }
  public masters() { 
    const sectionsWithLabelOnProfile = [
      'citizenships', 'relationship-statuses', 'diets', 'drinking-habits',
      'smoking-habits', 'pet-information', 'employment-types',
      'employment-natures', 'work-patterns',
      'work-timings', 'exercise-frequencies', 'alcohol-frequencies',
      'alcohol-quantities', 'mobility', 'hearing', 'speaking', 'visibility',
      'expense-categories', 'weekend-activities', 'industries', `hobbies`, `medical-conditions`,
      `pregnancy-intention-periods`, `pregnancy-prevention-importances`
    ];
    const getEndPoint = (section: string, operation = null, id = null, config: any = null) => {
      let url = `masters/sections`; 
      if(section == 'reports') { 
        const segment = config?.params?.params?.segment
        console.log(`Segment is`, config.params)
        const {params: { params: {filter, type, reportSection}}} = config 
        url = `/reports/${reportSection}/${type}/${filter}`; 
      } 
      else {
        const version = sectionsWithLabelOnProfile.indexOf(section) != -1?'v1' : 'v2';
      
        const detailsOperation = 'details';
        const deleteOperation = 'delete';
        let appendVersion = operation && (operation != detailsOperation) && (operation != deleteOperation);
        url = !appendVersion?url: `${url}/${version}`;
        url = `${url}/${section}`; 
      
        if(id)
          url = `${url}/${id}`;  
      }
      return this.getFullUrl(url);
    };
    return {
      sectionsWithLabelOnProfile,
      getEndPoint, 
      getItems: (section: string) => { 
        return new Promise((resolve, reject) => {
          try {
            const url = getEndPoint(section);
            this.get(url).subscribe((response) => { 
              let result = response.success? response.items: []; 
              resolve(result);
                     
            });

          } catch(err) {
            reject(err);
          } 
        });
      },
      getEntityCounts: () => {
        const url = this.getFullUrl(`masters/entity-counts`);  
        return this.httpClient.get<any>(url);   
      },
      list: (section) => {
        let url = this.getFullUrl(`masters/${section}`); 
        return this.httpClient.get<any>(url);  
      },
    }
  }

  public async getAllItems(section, labelField = "label", valueField = "id") {
    let items: any = await this.masters().getItems(section);
    return items.map((item) => { 
        return {
            label: item[labelField],
            value: item[valueField]
        }
    }); 
  }
  public sendPushNotification(id) {
    const url = this.getFullUrl(`masters/custom-push-notifications/${id}/send`);  
    return this.getHttpClient().post<any>(url, {});  
  } 
  public reSendPushNotification(id) {
    return new Promise((resolve, reject) => {
      try {  
        this.sendPushNotification(id).subscribe((response) => {
          resolve(response);
        });  
      } catch(err) {
        reject(err);
      }
    });
  } 
  public loadQuestionCategories() {
    const url = this.masters().getEndPoint(`question-categories`);  
    return this.getHttpClient().get<any>(url, {});  
  }
}
