import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  getDecodedAccessToken(token: string): any {
    //custom fn by Jinu 
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  jwtToken: any;
  constructor(public auth: ApiService, private router: Router, private authService: NbAuthService) { }
  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> { 
        const url = req.url; 
        const urlsToIgnore = [ 
          '/accounts/send-reset-password-link', 
          '/accounts/reset-password',
          '/accounts/is-reset-password-token-valid' 
        ];
        let urlToCheck = url.split('?')[0];
        console.log(`Url to check is `, urlToCheck);
        let ignore = false;
        urlsToIgnore.map((ignoreUrl) => { 
          if(!ignore)
            ignore = urlToCheck.endsWith(ignoreUrl);
        }); 
        this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
            const role = "guest"; 
            if(token.isValid()) { 
                const jwtToken = token.getValue(); 
                if(jwtToken) { 
                   this.jwtToken = jwtToken;  
                }  
            } else { 
              localStorage.clear();
              console.log(`Value for ignore is`, ignore);
              if(!ignore)
                this.reloadCurrentRoute()
              console.log('TOKEN IS INVALID');
            }   
        });
        if (this.jwtToken) {
            req = req.clone({
              headers: req.headers.set("Authorization",
                "Bearer " + this.jwtToken)
            }); 
            return next.handle(req).pipe(catchError(err => {
              if (err.status === 401) {
                localStorage.clear();
                this.reloadCurrentRoute()
              } else if(err.status == 405) {
                this.router.navigate(['/auth/not-allowed']); 
              }
              const error = err;
              return throwError(error);
            }));
          }
          else {
            return next.handle(req);
          }
      return next.handle(req);
    }
  }




